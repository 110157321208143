export default function Input(props){

    return(
        <div className="input-block-admin">
            <label>{props.label}:</label>
            {props.type=='textarea' ? 
            <textarea id={props.id} onKeyDown={props.onKeyDown} name={props.name} value={props.value} onChange={props.inputChanged}/>
            :
            <input id={props.id} onKeyDown={props.onKeyDown} name={props.name} value={props.value} onChange={props.inputChanged}/>
            }
            {props.buttonAction != null ?
            <div onClick={()=>props.buttonAction(props.id,props.value)} className="add-button">Add</div>
            :""}
        </div>
    )
}