export default function ThreeReasons(){

    const values=[
        {
            title:"Notre network",
            icon:"net.svg",
            text:"LM Platinum Estates dispose d'un réseau national unique et a ainsi accès à un grand nombre d'acheteurs potentiels."
        },
        {
            title:"Notre service",
            icon:"serv.svg",
            text:"La compétence, l'exclusivité et la passion ainsi que notre service personnalisé et complet caractérisent la marque LM Platinum – du premier conseil individuel à la conclusion réussie d'un contrat."
        },
        {
            title:"Notre expertise",
            icon:"loc.svg",
            text:"Nos agents immobiliers disposent d'une expertise approfondie et d'une connaissance du marché dont vous bénéficierez en matière de concept de service fiable."
        },
    ];

    return(
        <>
            <img id="reasons-image" src="reasons.jpg"/>
            <div className="padding-block-content">
                <div id="title">
                Trois raisons pour lesquelles nous sommes le partenaire idéal pour vous
                </div>
                <div id="values-section">
                    {values.map(item=>
                        <div id="reasons-card">
                            <div id="value-title">
                                <img src={item.icon}/>
                            <label> {item.title}</label>
                            </div>
                            <div id="value-text">
                                {item.text}
                            </div>
                        </div>
                        )}
                </div>
            </div>
        </>
    )
}
