import SocialLinks from "./social-links";
import * as Query from "../static data/queryparams";
import { useLocation, useNavigate } from "react-router-dom";
import {menuitems} from "../static data/menu-items"

export default function Footer(){
    const location = useLocation();
    const navigate = useNavigate();  
    function goToPage(link){
        // navigate("/apartments-search?"+Query.property + "=" + link);
         navigate({
             pathname: "/apartments-search",
             search: Query.property + "=" + link
         });
         if(location.pathname=="/apartments-search")navigate(0);
     }

     function goToServices(){
        navigate({
            pathname: "/main",
            hash: "#services"
        });
        if(location.pathname=="/main")navigate(0);
    }    
    return(
        <>
            <div className="footer">
                <div id="block">
                    <div id="title">
                        Archeter
                    </div>
                    <div id="contacts">
                    {menuitems[1].submenu.map(x=>
                                        <>
                                            <div className="link-item" onClick={()=>goToPage(x.link)}>
                                                {x.name}
                                            </div>
                                        </>
                                    )}
                    </div>
                </div>
                <div id="block">
                    <div id="title">
                        Louer
                    </div>
                    <div id="contacts">
                    {menuitems[2].submenu.map(x=>
                                        <>
                                            <div className="link-item" onClick={()=>goToPage(x.link)}>
                                                {x.name}
                                            </div>
                                        </>
                                    )}
                    </div>
                </div>
                <div id="block">
                    <div id="title">
                        Nos Services
                    </div>
                    <div id="contacts" >
                        <div onClick={goToServices} className="clickable">
                        ACHETER
                        </div  >
                        <div onClick={goToServices}  className="clickable"> 
                        LOUER
                        </div>
                        <div onClick={goToServices}  className="clickable"> 
                        CONSTRUCTION
                        </div>
                    </div>
                </div>
                <div id="block">
                    <div id="title">
                        Contacter nous
                    </div>
                    <div id="contacts">
                        <div>
                        Email : <a href="mailto:admin@lmplatinumestates.cm">admin@lmplatinumestates.cm</a><br/>
                        </div>
                        <div> 
                            Calls/WhatsApp : <a href="tel:+237 692 85 05 84">+237 692 85 05 84</a><br/>
                        </div>
                        <div> 
                        Calls : <a href="tel:+237 651 12 50 90">+237 651 12 50 90</a> / <a href="tel:+237 658 91 74 77">+237 658 91 74 77</a><br/>
                        </div>
                    </div>
                </div>
            </div>
            
            <div id="social-footer-icons">
                    <SocialLinks/>
            </div>
        </>
    )
}