export const propertyValues = [
    "Appartement meublé"
    ,"Bureau"
    ,"Chambre"
    ,"Chambre meublé"
    ,"Duplex"
    ,"Fond de Commerce"
    ,"Hôtel"
    ,"Immeuble R+"
    ,"Local Commercial"
    ,"Maison"
    ,"Maison d'hôte"
    ,"Meublé"
    ,"Studio"
    ,"Studio meublé"
    ,"Terrain"
    ,"Villa"
    ,"Villa meublé"
];
export const offerValues = [
    "A louer"
    ,"A vendre"
];

export const ville = [
    "Centre commercial", "Elig-Essono", "Etoa-Meki 1", "Nlongkak", "Elig-Edzoa", "Bastos", "Manguier", "Tongolo", "Mballa 1", "Mballa 2", "Nkolondom", "Etoudi", "Messassi", "Okolo", "Olembe", "Nyom", "Etoa-Meki 2", "Mballa 3", "Emana", "Nkoleton", "Cite Verte", "Madagascar", "Mokolo", "Grand Messa", "Ekoudou", "Tsinga", "Nkom-Kana", "Oliga", "Messa Carrière", "Ecole de Police", "Febe", "Ntoungou", "Obili", "Ngoa-Ekele 1", "Nlong Mvolye", "Ahala 1", "Efoulan", "Obobogo", "Nsam", "Melen 2 - Centre Administratif", "Etoa", "Nkolmesseng 1", "Afanoya 1", "Afanoya 2", "Afanoya 3", "Afanoya 4", "Nkolfon", "Mekoumbou 2", "Ntouessong", "Mekoumbou 1", "Ahala 2", "Nsimeyong 1", "Nsimeyong 2", "Nsimeyong 3", "Olezoa", "Dakar", "Ngoa-Ekele 2", "Mvan-Nord", "Ndamvout", "Messamendongo", "Odza", "Ekoumdoum", "Awae", "Nkomo", "Ekounou", "Biteng", "Kondengui 1", "Mimboman 1", "Etam-Bafia", "Mvog-Mbi", "Nkol-Ndongo 2", "Mebandan", "Mvan-Sud", "Ekie", "Emombo", "Kondengui 2", "Kondengui 3", "Nkol-Ndongo 1", "Mimboman 3", "Ntui-Essong", "Nkolo", "Abom", "Mvog-Ada", "Essos", "Nkol-Messing", "Nkol-Ebogo", "Quartier Fouda", "Ngousso 1", "Eleveur", "Mfandena 1", "Mfandena 2", "Ngousso 2", "Ngousso-Ntem", "Ngoulmekong", "Melen 8B et C", "Etoug-Ebe 2", "Mvog-betsi", "Biyem-Assi", "Mendong 2", "Melen 8", "Simbock", "Etoug-Ebe 1", "Melen", "Elig-Effa", "Nkolbikok", "Simbock Ecole de guerre", "Etetak", "Oyom-Abang", "Nkolbisson", "Minkoameyos", "Nkolso", "Mbandjock", "Nkoteng", "Batchenga", "Lobo", "Evodoula", "Monatélé", "Obala", "Okola", "Sa’a", "Esse", "Mfou", "Nkolafamba", "Soa", "Mbankomo", "Awae Escalier", "Titi garage", "Mfandena", "Nsimalen", "Ekoko", "Toutouli", "Manasa", "Mendong 1", "Nkolondom", "Messassi", "Ebang", "Tsinga Village", "Nkozoa", "Nyomo", "Ekoko", "Elat", "Nkolafamba", "Nkogoa", "Nkometou", "Nkoulou carrière", "Mehandan", "Olembe", "Nkometou", "Elat", "Ekoumdoum", "Ngousso", "Omnisport", "Monti", "Barriere", "Nkomo"
]
export const chambersValues = [1,2,3,4,5,6,7,8,9,"10+"];
export const bathValues = [1,2,3,4,5,6,7,8,9,"10+"];