import { BrowserView, MobileView, isMobile } from "react-device-detect";

export default function OurServices(){
    const values=[
        {title:"ACHETER",
        text:
        "Acheter des biens immobiliers chez LM Platinum Estates c’est choisir des biens titrés et vérifiés auprès des autorités compétentes. Achetez vos terrains, maisons, immeubles et autres biens immobiliers en un seul clic. Nos offres sont disponibles à Yaoundé, Douala et Kribi principalement mais aussi dans d’autres villes du Cameroun."
        +"Consultez notre catalogue de terrains et maisons à vendre."
        },
        {
            title:"LOUER",
text:"Nous mettons à votre disposition des biens de qualité à des prix compétitifs. Nos conditions de travail sont claires, des contrats de location transparents pour éviter des abus en tout genre."
+"Vous recherchez une maison à louer, un appartement à louer, une boutique ou autres biens à louer. "
+"Consultez notre catalogue de biens immobiliers à louer."
    },
        {title:"CONSTRUCTION ",
        text:"Notre équipe d’expert en BTP se tient à vos dispositions pour la réalisation de vos projets de construction clés en main.\n\r "
        +"Notre objectif des biens solides et agréable à vivre.\n\r"
        +" Nos compétences :\n\r"
        +    "  - Programmation et étude de faisabilité\n\r"
        +    "  - Élaboration des plans et conception schématique\n\r"
        +    "  - Documents contractuels\n\r"
        +    "  - Construction"
}];

    return <>
        <div id="our-services">
            <div>
                <div id="title">
                    {values[0].title}
                </div>
                <div id="text">                    
                    {values[0].text}
                </div>
                <div id="image">
                    <img src="s1.jpg"/>
                </div>
            </div>
            {!isMobile ? 
            <div>                
                <div id="image">
                    <img src="s2.jpg"/>
                </div>
                <div id="title">
                    {values[1].title}
                </div>
                <div id="text">                    
                    {values[1].text}
                </div>
            </div>
            :
            <div>       
                <div id="title">
                    {values[1].title}
                </div>
                <div id="text">        
                    {values[1].text}   
                </div>         
                <div id="image">
                    <img src="s2.jpg"/>
                </div>
            </div>
            }
            <div>        
                <div id="title">
                    {values[2].title}
                </div>
                <div id="text">                    
                    {values[2].text}
                </div>        
                <div id="image">
                    <img src="s3.jpg"/>
                </div>
            </div>
        </div>
    </>
}