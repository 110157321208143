
import * as Query from "../static data/queryparams";

import * as Constants from "../config";
import { useEffect, useState } from "react";
import Header from "../components/header";
import LeftMenu from "../components/left-menu";
import ApartCard from "../components/apart-card";
import { BrowserView, MobileView } from "react-device-detect";
import ContactUs from "../components/contact-us";

export default function Details(){
    
    const [apartid, setApartId] = useState(getUrlParams);  
    const [apartment, setApartment] = useState();
    const [allAparts, setAllAparts] = useState();
    const [enlargeimg, setenlargeimg] = useState();
    const [modalOpened,setModalOpened] = useState(false);

    function getUrlParams(){
        var id;
        let search = window.location.search;
        let params = new URLSearchParams(search);
        id = params.get(Query.id);
        console.log(id);
        return id;
    }

    function getData() {
        var id;
        let search = window.location.search;
        let params = new URLSearchParams(search);
        id = params.get(Query.id);
        fetch(Constants.apiurl + Constants.apartments, Constants.requestHeaderGet())
        .then((response) => response.json())
        .then((data) => {
            setAllAparts(data);
            var filtered = data.find(x=>x.id === id);
            console.log(filtered);
            setApartment(filtered);
        })
        .catch((err) => {
           console.log(err.message);
        });
    }

    function goToAnchor(anchor){
        const posy = document.getElementById(anchor)?.getBoundingClientRect().top;
        console.log(posy);
        document.getElementById(anchor)?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });   
        const yOffset = -70; 
        const y = posy + window.scrollY + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
    }

    function CloseModal(){
        setModalOpened(false);
    }

    useEffect(() => {
        getData();
     }, []);
    useEffect(() => {        
        goToAnchor("main-page-content");
     }, [apartment]);

     function apartsList(){
        var sameProperty = allAparts.filter(x=>x.propertytype == apartment.propertytype);
        if(sameProperty == null || sameProperty.count == 0){
            sameProperty = allAparts.filter(x=>x.displayOnMain == true);
        }
        return sameProperty.map(x=>
            <div key={x.id}>
                <ApartCard data={x}/>
            </div>
        )
     }

     return (
        <>
            {modalOpened ? <ContactUs closeModal = {CloseModal}/> : ""}
            {enlargeimg != null && enlargeimg != ""
            ?
            <div onClick={()=>setenlargeimg(null)} className="enlarge-block">
                <img  src={enlargeimg}/>
            </div>
            :
            ""
            }
            <Header/>        
            <div id="page">
                <div className="left-menu">
                    <LeftMenu/>
                </div>
               
                <div id='main-page-content' className="main-page-content">     
                    <div className="apart-photos">
                        {apartment != null && apartment.images != null ? apartment.images.map(x=>
                            <>
                                <img onClick={()=>setenlargeimg(x.link)} src={x.link} />
                            </>                    
                            ) : ""} 
                    </div>
                    <br/>
                    {apartment != null ?
                    <div className="full-apart-description">
                        <div id="title">
                            {apartment.name}
                        </div>

                        <BrowserView>
                        
                        <br/>
                        <table>
                            <thead>
                                <th>Prix</th>
                                {apartment.propertytype == "Terrain"
                                ?
                                ""
                                :
                                <>                                 
                                    <th>Chambres</th>
                                    <th>Douches</th>
                                </>
                                }
                                <th>Surface habitable</th>
                                <th>Superficie</th>
                            </thead>
                            <tbody>
                                <tr>
                                   <td>{apartment.price} FCFA {apartment.propertytype =='Terrain' ? 'par m²' :"" }</td> 
                                   {apartment.propertytype == "Terrain"
                                ?
                                ""
                                :
                                <>   
                                    <td>{apartment.bedrooms}</td> 
                                    <td>{apartment.showers}</td> 
                                </>
                                }
                                   <td>{apartment.livingarea} m²</td> 
                                   <td>{apartment.landarea} m²</td> 
                                </tr>
                            </tbody>
                        </table>
                        </BrowserView>
                        <MobileView>
                        <table className="mobile-table">
                            <tbody>
                                <tr><th>Prix</th> <td>{apartment.price} FCFA</td> </tr>
                                <tr><th>Chambres</th><td>{apartment.bedrooms}</td> </tr>                                   
                                <tr><th>Douches</th><td>{apartment.showers}</td> </tr>
                                <tr><th>Surface habitable</th><td>{apartment.livingarea} m²</td> </tr>
                                <tr><th>Superficie</th><td>{apartment.landarea} m²</td></tr>
                            </tbody>
                        </table>
                        </MobileView>
                        
                        <div id="features">
                            {apartment.properties != null ? apartment.properties.map(x=>
                                <div>
                                    <div id="title">{x.name}</div>
                                    <div id="options">
                                        {x.options != null ? x.options.map(y=>
                                            <div>{y}</div>
                                            ) :""}
                                    </div>
                                </div>
                                
                                ) : ""}
                        </div>
                        
                        <div className="blue-button" onClick={()=> setModalOpened(true)}>Cet article m'interesse</div>
                    </div>                    
                 : ""}
                 
                    <div id="check-other">                                
                                Consultez d’autres propositions'
                            </div>
                            <div id="aparts-cards">                                
                                
                                {allAparts != null ? apartsList() : ""}
                    </div>
                 </div>
                  {/* <div className="main-page-content">    
                  <div id="not-found">Content not found</div>
                  <div id="check-other">                                
                                 Consultez d’autres propositions'
                             </div>
                             <div id="aparts-cards">                                
                                
                                 { allAparts != null ? apartsList(allAparts.filter(x=>x.displayOnMain == true) ) : ""}
                    </div>
                  </div> */}
                 
            </div>
        </>
     )

}