import { useEffect, useState } from "react";
import Input from "./admin/input";
import uuidv4 from "../tools/guidgenerator";
import * as Constants from "../config";
import * as Vaules from "../static data/selectvalues";
import { useLocation } from "react-router-dom";
import { checkArrayDefined } from "../tools/valueshelper";

export default function ContactUs(props){

    const datar = {
        name:"",
        phone:"",
        email:"",
        message:"",
        link:""
    }

    const validationErrorData = {
        name:false,
        email:false,
        phone:false,
    }
    
    const location = useLocation();  

    const [data,setData] = useState(datar);
    const [validationError, setValidationError] = useState(validationErrorData);
    const [hasValidationError, setHasValidationError] = useState(false);
    const [messageSent, setMessageSent] = useState(false);

    const validate = () => {
        let haserror = false;
        let validationErrorNew = {};
        if(!data.email.match("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"))
        {
            haserror = true;
            validationErrorNew.email = true;
        }
        else
            validationErrorNew.email = false;
        if(data.phone.length < 6 || data.phone.length > 30)
        {
            haserror = true;
            validationErrorNew.phone = true;
        }
        if(validationErrorNew.phone && !validationErrorNew.email)
        {
            validationErrorNew.phone = false;
            haserror = false;
        }
        if(validationErrorNew.email && !validationErrorNew.phone)
        {
            validationErrorNew.email = false;
            haserror = false;
        }
        if(data.name.length < 2 || data.name.length > 30)
        {
            haserror = true;
            validationErrorNew.name = true;
        }

        setValidationError(validationErrorNew);
        setHasValidationError(haserror);
        return haserror;
    }


    const onValueChange = (e) =>{        
        const { id, name, value } = e.target;        
        setData({...data, [name]: value});   
    }

    useEffect(() => {
        if(hasValidationError) validate();
     }, [data]);

    const saveData = () => {
        if(validate()) return;
        let savedataObj = {};
        Object.assign(savedataObj,data);
        console.log(123);
        savedataObj.link = window.location.href;
        if(checkArrayDefined(props.service))
            savedataObj.service = props.service;
        const body = Constants.requestBody(savedataObj);
        fetch(Constants.apiurl + Constants.sendcontactinfo, body)
        .then(response => {            
            setMessageSent(true);
            return response.json();
        })
        .then(data => {            
        })
        .catch(err => {
            console.log(err.error);
        });
    }

    const sendData =                 
    <div>
        <div onClick={props.closeModal} className='close-icon-main'>X</div>
        <div id="modal-header">
            Give your contact and we come to you back
        </div>
        { checkArrayDefined(props.service) ?
            <div id="input-block-modal" className="hardlined">
                {validationError.name ?
                    <label className="error-label">Input Name</label>
                    :
                    ""
                }
                <label >Service:</label>

                <div className="bolded">{props.service}</div>
            </div>
            :""
        }
        <div id="input-block-modal">
            {validationError.name ?
                <label className="error-label">Input Name</label>
                :
                ""
            }
            <label >Full Name:</label>

            <input value={data.name} name='name' onChange={onValueChange}/>
        </div>
        <div id="input-block-modal">                        
            {validationError.phone ?
                <label className="error-label">Input Phone</label>
                :
                ""
            }
            <label>Phone number:</label>                        

            <input value={data.phone} name='phone' onChange={onValueChange}/>
        </div>
        <div id="input-block-modal">                        
            {validationError.email ?
                <label className="error-label">Input correct Email</label>
                :
                ""
            }
            <label>Email:</label>
            <input value={data.email} name='email' onChange={onValueChange}/>
        </div>
        <div id="input-block-modal">                        
            <label>Message:</label>
            <textarea value={data.message} name='message' onChange={onValueChange}/>
        </div>
        <div onClick={saveData} className="blue-button">Submit</div>
    </div>;

    return(
        <>
            <div className="modal-window-small">
                {messageSent ? 
                <>        
                    <div>
                        <div onClick={props.closeModal} className='close-icon-main'>X</div>
                        <div id="modal-header">
                                Your message has been sent
                        </div>
                        <div  onClick={props.closeModal}  className="blue-button">OK</div>
                    </div>
                </>
                :
                sendData}
            </div>
        </>
    )
}