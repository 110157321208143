import { useState } from "react";
import * as SelectValues from "../static data/selectvalues";
import * as Query from "../static data/queryparams";

export default function Autocomplete(props){

    const [search,setSearch] = useState(SelectValues.ville);
    const [searchingValue,setSearchingValue] = useState(props.value);
    const [showSearchDiv,setShowSearchDiv] = useState(false);

    const searching = (e) =>{
        const { id, name, value } = e.target;  
        setSearchingValue(value);
        props.inputChanged({target:{name:Query.neighborhood,value:value}});
        var a = SelectValues.ville.filter(x=>x.toLowerCase().includes(value.toLowerCase()));
        setSearch(a);
    }
    
    const setValue = (x) =>{ 
        setSearchingValue(x);
        props.inputChanged({target:{name:Query.neighborhood,value:x}});
        console.log(x);
    }

    return(
        <>
        <div className="input-block">
            <div>{props.label}:</div>

            {/* <select className="input-small" name={props.name} value={props.value} onChange={props.inputChanged}>
                <option value="">Tout</option>
                {props.values.map((x,index)=>
                    <option value={x}>{x}</option>
                )}
            </select> */}
            
            <input onFocus={()=> setShowSearchDiv(true)} onBlur={()=> setShowSearchDiv(false)} value={searchingValue} onChange={searching} className="input-small"/>
        </div>
        {showSearchDiv ?
        <div className="auocomplete-div">
            {search.map(x=>
                <li onMouseEnter={()=>setValue(x)}>{x}</li>)}
        </div>
        :
        ""
        }
        </>
    )
}